import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";

import Layout from "../components/layout";
import Seo from "../components/seo";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WorksPage = ({ data }: any) => {
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    setImages(data.slideshow.edges);
  }, [data?.slideshow?.edges]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Layout>
      <div>
        <h2>Τα έργα μας</h2>

        <Slider {...settings}>
          {images?.map(({ node }: any) => (
            <GatsbyImage
              image={node.childrenImageSharp[0].gatsbyImageData}
              alt={node.base}
            />
          ))}
        </Slider>
      </div>
    </Layout>
  );
};

export const Head = () => <Seo title="Works Virtual Sun" />;

export default WorksPage;

export const pageQuery = graphql`
  query {
    slideshow: allFile(filter: { relativeDirectory: { eq: "carousel" } }) {
      edges {
        node {
          id
          name
          relativeDirectory
          relativePath
          base
          childrenImageSharp {
            gatsbyImageData(
              webpOptions: { quality: 70 }
              width: 900
              height: 600
              placeholder: BLURRED
              blurredOptions: { width: 100 }
              layout: FULL_WIDTH
              transformOptions: { cropFocus: CENTER, fit: COVER }
            )
          }
        }
      }
    }
  }
`;
